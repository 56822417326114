import React, { ReactElement } from "react";
import colors from "@styles/variables/iForkLiftVariables.module.scss";

import FindForkliftIcon from "../icons/findForkliftIcon";
import ForkliftDealsIcon from "../icons/forkliftDealsIcon";
import NewForkliftIcon from "../icons/newForkliftIcon";
import UsedForkliftIcon from "../icons/usedForkliftIcon";
import ForkLiftEngineIcon from "../icons/forkLiftEngineIcon";
import CompareForkliftTypesIcon from "../icons/compareForkliftTypesIcon";
import ForkliftRentalIcon from "../icons/forkliftRentalIcon";
import MultipleForkliftQuotesIcon from "../icons/multipleForkliftQuotesIcon";
import CompareForkliftBrandsIcon from "../icons/compareForkliftBrandsIcon";
import FeatureList from "@components/shared/featureList";

const whyUsList = [
    {
        title: "Find the Top Forklifts",
        description:
            "Compare the features, prices & deals of the top forklift manufacturers.",
        icon: <FindForkliftIcon />,
    },
    {
        title: "Find Forklift Deals",
        description:
            "Our network of reputable dealers offers only the best forklifts.",
        icon: <ForkliftDealsIcon />,
    },
    {
        title: "Get Multiple Quotes",
        description:
            "Get multiple quotes for several brands of forklifts at the same time.",
        icon: <MultipleForkliftQuotesIcon />,
    },
    {
        title: "New Forklifts",
        description:
            "Our dealers offer brand new forklifts that come directly from the manufacturer.",
        icon: <NewForkliftIcon />,
    },
    {
        title: "Used Forklifts",
        description:
            "Our dealers offer quality used & well-inspected forklifts.",
        icon: <UsedForkliftIcon />,
    },
    {
        title: "Forklift Rentals",
        description:
            "You can rent the ideal machine for your specific weight capacity & lift height.",
        icon: <ForkliftRentalIcon />,
    },
    {
        title: "Compare Forklift Types",
        description:
            "Compare the different types of forklifts to understand which one suits your needs best.",
        icon: <CompareForkliftTypesIcon />,
    },
    {
        title: "Compare Forklift Engines",
        description:
            "Let your cargo transfer more efficiently with the best engines on the market.",
        icon: <ForkLiftEngineIcon />,
    },

    {
        title: "Compare Top Brands",
        description:
            "Find quality forklifts coupled with excellent customer service to get the best quality per dollar.",
        icon: <CompareForkliftBrandsIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle={
                <>
                    Why Choose <strong>IForkLift?</strong>
                </>
            }
            classes={{
                mainTitleClasses: "text-center text-xl lg:text-2xl mb-14",
                innerSectionClasses: "lg:flex flex-wrap justify-center",
                oneBoxClasses: "text-center mb-12 lg:mb-20 w-full lg:w-1/3",
                iconClasses: "children:w-12 children:h-12 children:mx-auto",
                titleClasses: "font-medium text-lg lg:text-xl mt-6",
                descriptionClasses:
                    "font-light text-sm lg:text-base text-center w-4/5 mx-auto mt-2",
            }}
            colors={{
                titleColor: colors.youngNight,
                descriptionColor: colors.youngNight,
                iconColor: colors.citrusSplash,
            }}
        />
    );
}
